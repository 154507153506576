import { rootApi } from "app/rootApi";
import { GetAuditBodyDto, GetAuditParams } from "./types";
import { checkCurrentLanguage } from "shared/lib/checkCurrentLanguage";
import dayjs from "dayjs";

const transformAuditParams = (params: GetAuditParams) => {
  const filter = [];
  let sorting: string = "";
  if (params.isDisabledPath && !params.isArchived) {
    filter.push("document!=null,document.disabilityPath!=null");
  }
  if (
    params.operationType &&
    params.operationType !== undefined &&
    params.operationType !== null
  ) {
    filter.push(`operationType=${params.operationType}`);
  }
  if (
    params.documentType &&
    params.documentType !== undefined &&
    params.documentType !== null
  ) {
    filter.push(`documentType=${params.documentType}`);
  }
  if (params.timestamp) {
    filter.push(
      `date>${dayjs(params.timestamp)
        .subtract(1, "d")
        .format("YYYY-MM-DD")},date<${dayjs(params.timestamp)
        .add(1, "d")
        .format("YYYY-MM-DD")}`
    );
  }
  if (params.sorting && params.sorting.length > 0) {
    params.sorting.forEach((item: any) => {
      sorting = `${item.id} ${item.desc ? "desc" : "asc"}`;
    });
  } else {
    sorting = "timestamp desc";
  }
  filter.push(`isArchived=${params.isArchived}`);
  return {
    page: params.page,
    pageSize: params.pageSize,
    searchTerm: params.searchTerm,
    filter: filter.join(","),
    orderBy: sorting,
  };
};

export const documentsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getAudit: build.query<GetAuditBodyDto, GetAuditParams>({
      query: (params) => ({
        url: `/documents/audit-document`,
        params: transformAuditParams(params),
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }),
      providesTags: () => ["Audit"],
    }),
    markNotification: build.mutation<unknown, number>({
      query: (id) => ({
        url: `/documents/audit-document/${id}/mark-as-read`,
        method: "POST",
      }),
    }),
    deleteNotification: build.mutation<unknown, Record<string, boolean>>({
      query: (notifIds) => {
        const params = new URLSearchParams();
        if (Object.keys(notifIds).length > 0) {
          Object.keys(notifIds).forEach((id) => {
            params.append("ids", id);
          });
        }
        return {
          url: `/documents/audit-document?${params.toString()}`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => ["Audit"],
    }),
  }),
});

export const {
  useGetAuditQuery,
  useMarkNotificationMutation,
  useDeleteNotificationMutation,
} = documentsApi;
