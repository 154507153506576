import { ChangeEvent, useMemo, useState } from "react";
import styles from "../Notifications.module.scss";
import searchIcon from "assets/images/admin/search-icon.svg";
import filterIcon from "assets/images/admin/filter_icon.svg";
import filterCloseXIcon from "assets/images/admin/btn_filter_closeX.svg";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import UsersFiltersSelect from "shared/ui/adminPanel/usersFiltersSelect/usersFiltersSelect";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarIcon, CloseIcon, TrashIcon } from "assets/icons";
import {
  MaterialReactTable,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from "material-react-table";
import { useDebounce, useLocalStorage } from "shared/lib/hooks/hooks";
import {
  useDeleteNotificationMutation,
  useGetAuditQuery,
} from "shared/queries/Audit/queries";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { ModalTitle } from "entities/admin/lib/MUIStyles/MUIStyles";
import ButtonWithProgress from "shared/ui/ButtonWIthProgress/ButtonWithProgress";
import { useNavigate } from "react-router-dom";
import { ADMIN_PANEL_PATH } from "pages/paths";

const documentOptions = [
  {
    name: "Паспорт (лицевая сторона)",
    id: "0",
  },
  {
    name: "Паспорт (задняя сторона)",
    id: "3",
  },
  {
    name: "Справка о здоровье (лицевая сторона)",
    id: "1",
  },
  {
    name: "Справка о здоровье (задняя сторона)",
    id: "4",
  },
  {
    name: "Справка о инвалидности (лицевая сторона)",
    id: "2",
  },
  {
    name: "Справка о инвалидности (задняя сторона)",
    id: "5",
  },
];

const actionOptions = [
  {
    name: "Изменение",
    id: "2",
  },
  {
    name: "Загрузка",
    id: "0",
  },
  {
    name: "Удаление",
    id: "1",
  },
];

const getDocumentType = (documentType: number) => {
  switch (documentType) {
    case 0:
      return "Паспорт (лицевая сторона)";
    case 3:
      return "Паспорт (задняя сторона)";
    case 1:
      return "Справка о здоровье (лицевая сторона)";
    case 4:
      return "Справка о здоровье (задняя сторона)";
    case 2:
      return "Справка о инвалидности (лицевая сторона)";
    case 5:
      return "Справка о инвалидности (задняя сторона)";
    default:
      return "";
  }
};

const getOperationType = (operationType: number) => {
  switch (operationType) {
    case 2:
      return (
        <Box
          width={"min-content"}
          p="8px 16px"
          borderRadius={"8px"}
          sx={{ background: "#C8E3FF" }}
        >
          <Typography fontSize={"12px"} fontWeight={500} color={"#0090D1"}>
            Изменение
          </Typography>
        </Box>
      );
    case 0:
      return (
        <Box
          width={"min-content"}
          p="8px 16px"
          borderRadius={"8px"}
          sx={{ background: "#D4FFEF" }}
        >
          <Typography fontSize={"12px"} fontWeight={500} color={"#345A3D"}>
            Загрузка
          </Typography>
        </Box>
      );
    case 1:
      return (
        <Box
          width={"min-content"}
          p="8px 16px"
          borderRadius={"8px"}
          sx={{ background: "#FFECEB" }}
        >
          <Typography fontSize={"12px"} fontWeight={500} color={"#FA0E00"}>
            Удаление
          </Typography>
        </Box>
      );
    default:
      return "";
  }
};

const NotificationArchive = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageSize: 100,
    pageIndex: 1,
  });
  const [selectedRows, setSelectedRows] = useState<MRT_RowSelectionState>({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [documentType, setDocumentType] = useLocalStorage<string>(
    "archivedNotifDocumentType",
    ""
  );
  const [timestamp, setTimestamp] = useLocalStorage(
    "archivedNotifTimestamp",
    ""
  );
  const [operationType, setOperationType] = useLocalStorage<string>(
    "archivedNotifOperationType",
    ""
  );

  const [sorting, setSorting] = useLocalStorage("archivedNotifSort", [
    { id: "timestamp", desc: true },
  ]);
  const debouncedSearch = useDebounce(search, 300);
  const { isLoading, isFetching, data } = useGetAuditQuery({
    isDisabledPath: true,
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    timestamp: timestamp,
    searchTerm: debouncedSearch,
    operationType: operationType,
    documentType: documentType,
    sorting: sorting,
    isArchived: true,
  });

  const [deleteNotification, { isLoading: isDeleteLoading }] =
    useDeleteNotificationMutation();

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) =>
    setPagination({ ...pagination, pageIndex: page });

  const deleteNotificationsHandler = async () => {
    try {
      await deleteNotification(selectedRows).unwrap();
      toast.success("Уведомления успешно удалены", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSelectedRows({});
    } catch (error: any) {
      toast.error(error?.data?.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullName",
        header: "ФИО",
        enableSorting: true,
        size: 310,
        Cell: (value: any) => (
          <>
            <Typography fontSize={"14px"} fontWeight={600} color="#3E444A">
              {value.renderedCellValue}
            </Typography>
            <Typography fontSize={"12px"} fontWeight={500} color="#3E444A">
              {value.row.original.email}
            </Typography>
          </>
        ),
      },
      {
        accessorKey: "operationType",
        header: "Тип действия",
        enableSorting: true,
        size: 155,
        Cell: (value: any) => getOperationType(value.renderedCellValue),
      },
      {
        accessorKey: "timestamp",
        header: "Дата/время",
        enableSorting: true,
        size: 130,
        Cell: (value: any) =>
          dayjs(value.renderedCellValue.slice(0, 19) + "Z").format(
            "DD/MM/YYYY - HH:mm"
          ),
      },

      {
        accessorKey: "documentType",
        header: "Тип документа",
        enableSorting: true,
        size: 265,
        Cell: (value: any) => getDocumentType(value.renderedCellValue),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    //@ts-ignore
    columns,
    data: data?.query || [],
    enableTopToolbar: false,
    enableColumnActions: false,
    manualPagination: true,
    initialState: { density: "comfortable" },
    enablePagination: false,
    state: {
      pagination,
      isLoading: isLoading || isFetching,
      showLoadingOverlay: isDeleteLoading,
      sorting,
      rowSelection: selectedRows,
    },
    onSortingChange: (e) => {
      // @ts-ignore
      setSorting(e);
    },
    getRowId: (row) => {
      return row.id?.toString();
    },
    enableRowSelection: true,
    onRowSelectionChange: setSelectedRows,
    rowCount: data?.count,
    muiTableHeadRowProps: () => ({
      style: {
        background: "#ECF2F7",
        borderRadius: "20px",
        border: "none",
        boxShadow: "none",
      },
    }),
    muiTablePaperProps: {
      style: {
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        boxShadow: "none ",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        height: "10px",
        fontSize: "12px",
        lineHeight: "20px",
        fontWeight: 600,
        fontFamily: "Raleway",
        border: "none",
        "& .MuiTableSortLabel-icon": {
          color: "black !important",
          opacity: 1,
        },
      },
    },
    muiBottomToolbarProps: {
      style: {
        border: "none",
        boxShadow: "none ",
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        "& tr:nth-of-type(even):not(.Mui-selected) > td": {
          backgroundColor: "#F5FAFF",
        },
      },
    },
    muiTableBodyCellProps: {
      style: {
        fontSize: "12px",
        fontVariantNumeric: "lining-nums proportional-nums",
        textOverflow: "ellipsis",
        fontFeatureSettings: '"liga" off',
        lineHeight: "20px",
        fontWeight: 500,
        fontFamily: "Raleway",
        color: "#3E444A",
        border: "none",
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (e: any) => {
        e.stopPropagation();
        navigate(
          `/${ADMIN_PANEL_PATH}/users/disabled_people/${row.original.userId}`
        );
        row.getToggleSelectedHandler()(row.original.id);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    muiPaginationProps: {},
    localization: {
      rowsPerPage: "Строк в таблице",
    },
  });

  return (
    <section>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          justifyContent: "space-between",
          mb: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div className={styles.box_inputSearch}>
            <input
              type="text"
              placeholder="Введите ФИО или почту"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className="input-adminPanel"
            />
            <img src={searchIcon} alt="Поиск" />
          </div>
          <IconButton
            // onClick={deleteNotificationsHandler}
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            title="Delete selected notifications"
            disabled={isDeleteLoading || Object.keys(selectedRows).length <= 0}
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#212121",
              display: "flex",
              gap: "12px",
            }}
          >
            {isDeleteLoading ? (
              <CircularProgress size={24} className={styles.buttonProgress} />
            ) : (
              <TrashIcon />
            )}
            Удалить
          </IconButton>
          <button
            className={styles.btn_filter}
            onClick={() => setOpenFilter(!openFilter)}
          >
            <img
              src={openFilter ? filterCloseXIcon : filterIcon}
              alt={openFilter ? "Скрыть фильтры" : "Показать фильтры"}
            />
            <span>{openFilter ? "Скрыть фильтры" : "Показать фильтры"}</span>
          </button>
        </Box>
        {openFilter && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "16px",
              label: {
                flex: "auto",
              },
            }}
          >
            <Box display={"flex"} flexDirection={"column"} width={"33%"}>
              <Typography
                component="label"
                htmlFor={"dateFilter"}
                fontSize="12px"
                fontWeight={500}
                color="#68717B"
              >
                Дата
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  inputFormat="DD.MM.YYYY"
                  value={timestamp}
                  componentsProps={{
                    actionBar: {
                      actions: ["clear"],
                      style: { justifyContent: "flex-start" },
                    },
                  }}
                  onChange={(event) => {
                    setTimestamp(event as string);
                  }}
                  renderInput={({ inputProps, ...params }) => {
                    return (
                      <TextField
                        {...params}
                        inputProps={{
                          ...inputProps,
                          placeholder: "Выберите дату",
                          autoComplete: "off",
                          sx: {
                            "&::placeholder": {
                              fontStyle: "italic",
                            },
                          },
                        }}
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "10px",
                            border: "1px solid #C0D4E3 !important",
                          },
                        }}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </Box>
            <UsersFiltersSelect
              placeholder={"Выберите документы"}
              nameLabel={"Тип документа"}
              arr={documentOptions}
              selected={documentType}
              setSelected={setDocumentType}
            />
            <UsersFiltersSelect
              placeholder={"Выберите действие"}
              nameLabel={"Тип действия"}
              arr={actionOptions}
              selected={operationType}
              setSelected={setOperationType}
            />
          </Box>
        )}
      </Box>
      <MaterialReactTable table={table || []} />
      {data?.query.length === 0 ? null : (
        <Stack
          spacing={2}
          sx={{
            margin: "30px auto 20px auto",
            alignItems: "center",
          }}
          className={styles.tablePagination}
        >
          <Pagination
            count={
              Math.ceil((data?.count as number) / pagination.pageSize) || 1
            }
            page={pagination.pageIndex}
            onChange={handleOnPageChange}
            color="primary"
            size="medium"
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      )}
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          fontFamily: "Raleway",
          ".MuiDialog-paper": {
            width: "426px",
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle id="modal-modal-title" sx={ModalTitle}>
            Удалить уведомление?
          </DialogTitle>
          <IconButton onClick={() => setDeleteModalOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={{ paddingInline: 0 }}>
          <DialogContentText id="alert-dialog-description">
            Вы уверены что хотите удалить уведомления? При удалении невозможно
            восстановить данные
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{
            justifyContent: "flex-start",
            paddingInline: 0,
            marginTop: "16px",
          }}
        >
          <ButtonWithProgress
            loading={isDeleteLoading}
            disabled={isDeleteLoading}
            variant="contained"
            onClick={deleteNotificationsHandler}
            autoFocus
            sx={{ borderRadius: "16px" }}
          >
            Удалить
          </ButtonWithProgress>
          <Button onClick={() => setDeleteModalOpen(false)}>Отмена</Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default NotificationArchive;
